import React from "react";
import classNames from "classnames";
import { Stat, StatLong, StatShort } from "@molecules";

const Stats = ({ stats }) => {
  return (
    <ul className="flex flex-col gap-6">
      {stats.map((stat, i) => {
        switch (stat.type) {
          case "shortStats":
            return (
              <ul
                key={stat.uid}
                className={classNames(
                  "flex flex-wrap justify-center py-3 sm:flex-nowrap",
                  {
                    "border-t-3 border-white pt-6": i > 0,
                    "lg:grid lg:gap-3": stat?.stats?.length >= 2,
                    "lg:grid-cols-3": stat?.stats?.length === 3,
                    "lg:grid-cols-2": stat?.stats?.length === 2,
                  }
                )}
              >
                {stat.stats.map((s, ii) => (
                  <StatShort
                    // eslint-disable-next-line react/no-array-index-key
                    key={ii}
                    className={classNames(
                      "flex w-1/2 flex-col items-center gap-3 pt-6 lg:w-full",
                      {
                        "sm:border-l-3 sm:border-white": ii > 0,
                        "border-l-3 border-white": ii % 2 === 1,
                      }
                    )}
                    {...s}
                  />
                ))}
              </ul>
            );
          case "labelStatLabel":
            return (
              <StatLong
                key={stat.uid}
                className={classNames(
                  "flex w-full flex-col items-center justify-between gap-3 sm:flex-row",
                  {
                    "border-t-3 border-white pt-6": i > 0,
                  }
                )}
                {...stat}
              />
            );
          default:
            return (
              <Stat
                key={stat.uid}
                className={classNames(
                  "flex w-full flex-col items-center justify-between gap-3 sm:flex-row",
                  {
                    "border-t-3 border-white pt-6": i > 0,
                  }
                )}
                {...stat}
              />
            );
        }
      })}
    </ul>
  );
};

export default Stats;
